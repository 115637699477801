import React, { Component } from "react"
import { Link } from "gatsby"
import { FaPodcast } from "react-icons/fa";

import "./item.scss"

export default function PodcastItem(props) {
  return (
    <Link to={`${props.slug}`} className="podcast-item">
      <span className="media-type">
        <FaPodcast /> {props.posttype}
      </span>
      <img src={`/images/podcasts/${props.image}`} />
      <div>
        <h2>{props.title}</h2>
        <p className="description">{props.description}</p>
      </div>
    </Link>
  )
}
